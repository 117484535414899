import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Col, Row, Small } from '@bootstrap-styled/v4'
import SectionHeading from '../../SectionHeading'
import Section from '../../UI/Section'
import LandingPageObjects from '../../LandingPageObjects';

// import Icon from '../Icons'
// import breakpoints from '../../util/style/breakpoints'

const SectionObjects = ({ id, header, subTitle }) => (
  <Section id={id}>
    <Container>
      <SectionHeading header={header} subTitle={subTitle} />
      <LandingPageObjects />
    </Container>
  </Section>
)

SectionObjects.propTypes = {
  header: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default SectionObjects
