import React from 'react'
import VideoBg from 'reactjs-videobg'
import styled from 'styled-components'
import mp4 from '../../assets/videos/home_intro-720.mp4'
import webm from '../../assets/videos/home_intro-720.webm'
import ogv from '../../assets/videos/home_intro-720.ogv'

const BgVideoWrapper = styled.div`
  .bg-video-wrapper {
    video {
      background-size: cover;
      overflow: hidden;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
    }
    position: absolute;
    z-index: 1;
  }
`

function VideoBgPlayer() {
  return (
    <BgVideoWrapper>
      <VideoBg muted loop wrapperClass="bg-video-wrapper">
        <VideoBg.Source src={mp4} type="video/mp4" />
        <VideoBg.Source src={webm} type="video/webm" />
        <VideoBg.Source src={ogv} type="video/ogv" />
      </VideoBg>
    </BgVideoWrapper>
  )
}

export default VideoBgPlayer
