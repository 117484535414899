import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import FullScreenHero from '../components/Sections/FullScreenHero'
import ServicesBlock from '../components/Sections/ServicesBlock'
import SectionObjects from '../components/Sections/SectionObjects'
import Footer from '../components/Footer'
import ContactMe from '../components/ContactMe'
import GoogleReviewsBlock from '../components/Sections/GoogleReviewsBlock'
import Section from '../components/UI/Section'
import { H5 } from '../components/Typography'

const IndexPage = ({ intl }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: 'site_title' })} />
    <FullScreenHero
      header={intl.formatMessage({ id: 'header' })}
      ctaHeader={intl.formatMessage({ id: 'cta_header' })}
      ctaLink={intl.formatMessage({ id: 'navigation.nav_2_id' })}
      ctaText={intl.formatMessage({ id: 'cta_text' })}
      ctaHeader2={intl.formatMessage({ id: 'OFFER' })}
      ctaText2={intl.formatMessage({ id: 'SEE_CURRENT_OFFER' })}
      ctaLink2="/object-overzicht"
    />
    <Section id={intl.formatMessage({ id: 'navigation.nav_1_id' })}>
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" md="8">
            <H5 className="text-md-center">{intl.formatMessage({ id: 'header_2' })}</H5>
          </Col>
        </Row>
      </Container>
    </Section>
    <ServicesBlock landlords renters realEstateAgents/>
    <SectionObjects
      header={intl.formatMessage({ id: 'header_3' })}
      subTitle={intl.formatMessage({ id: 'sub_title_3' })}
    />
    <Section>
      <Container>
        <GoogleReviewsBlock />
      </Container>
    </Section>
    <ContactMe
      id={intl.formatMessage({ id: 'navigation.nav_3_id' })}
      header={intl.formatMessage({ id: 'contact.title' })}
      subTitle={intl.formatMessage({ id: 'contact.sub_title' })}
      contactUs={intl.formatMessage({ id: 'CONTACT_US' })}
    />
    <Footer withSkyline />
  </Layout>
)

export default injectIntl(IndexPage)
