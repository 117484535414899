import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { injectIntl } from 'gatsby-plugin-intl'
import styled from 'styled-components'
import { Col, Row, Container } from '@bootstrap-styled/v4'
import breakpoints from '../../util/style/breakpoints'
import { Link } from '../Link'
import Button from '../Buttons'
import ObjectPreviewCardWrapper from '../ObjectPreviewCard/wrapper'
import ObjectPreviewCard from '../ObjectPreviewCard'
import { slugify } from '../../util/js/slugify'

const Wrapper = styled.div`
  .object-col-md-padding {
    ${breakpoints.md`
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
  `};
  }
`

const LandingPageObjects = ({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        allSanityRealEstateProperty(
          limit: 6
          sort: { order: DESC, fields: publishedAt }
        ) {
          edges {
            node {
              id
              publishedAt
              rentOrBuyProperty
              propertyUrl
              transfer {
                street
                availabilty
                price
                cityArea {
                  city {
                    cityName
                  }
                  cityArea
                }
              }
              division {
                surfaceArea
                bathrooms
                bedrooms
              }
              outdoorSpace {
                balcony
                gardenFacilities
              }
              realEstateImages {
                _key
                _rawAsset
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const properties = data.allSanityRealEstateProperty.edges

      return (
        <Wrapper>
          <ObjectPreviewCardWrapper>
            {properties.length <= 0 && (
              <Container>
                <Row>
                  <Col className="object-col-md-padding mb-5">
                    <p>Geen objecten gevonden</p>
                  </Col>
                </Row>
              </Container>
            )}
            {properties.map(({ node }) => 
                <ObjectPreviewCard
                  key={`properties-${node.id}`}
                  url={`/${slugify(
                    node.transfer.cityArea.city.cityName
                  )}/${slugify(node.transfer.street)}/${node.id.substring(
                    0,
                    6
                  )}`}
                  rentOrBuyProperty={node.rentOrBuyProperty}
                  id={node.id}
                  previewImage={
                    node.realEstateImages &&
                    node.realEstateImages[0] &&
                    node.realEstateImages[0]._rawAsset
                  }
                  transfer={node.transfer}
                  division={node.division}
                  outdoorSpace={node.outdoorSpace}
                />
            )}
          </ObjectPreviewCardWrapper>

          <Row className="f-flex justify-content-center">
            <Col md={3} className="f-flex justify-content-center">
              <Link to="/object-overzicht">
                <Button
                  name="primary"
                  type="button"
                  className="mt-5 w-100"
                >
                  {intl.formatMessage({ id: 'ALL_OBJECTS' })}
                </Button>
              </Link>
            </Col>
          </Row>
        </Wrapper>
      )
    }}
  />
)

LandingPageObjects.propTypes = {}

export default injectIntl(LandingPageObjects)
