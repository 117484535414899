import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Container as BsContainer, Col, Row, Small } from '@bootstrap-styled/v4'
import { H1 } from '../../Typography'
import StyledBackgroundSection from '../../StyledBackgroundSection'
import Section from '../../UI/Section'
import Icon from '../../Icons'
import breakpoints from '../../../util/style/breakpoints'
import VideoBgPlayer from '../../VideoBgPlayer'
import { Link } from '../../Link'
import GoogleReviewsSticker from '../../GoogleReviews/GoogleReviewsSticker'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 2;
  background-color: rgba(23, 23, 23, 0.64);
  padding-top: calc(5rem + 73px);
  padding-bottom: 5rem;
  position: relative;

  ${breakpoints.sm`
    height: 100vh;
    padding-top: 30vh;
    padding-bottom: 0;
  `};

  ${breakpoints.lg`
    height: 90vh;
  `};

  .google-review-sticker-wrapper {
    position: absolute;
    top: 15%;
    right: 5%;

    ${breakpoints.lg`
      top: 20%;
      right: 10%;
  `};
  }
`

const Container = styled(BsContainer)`
  position: relative;
  height: 100%;
`

const SeeMore = styled.div`
  ${breakpoints.sm`
      display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      &:after {
        content: '';
        z-index: 5;
        background-color: ${(props) => props.theme['$gray-dark']};
        position: absolute;
        bottom: 0;
        left: 0;
        right: 50%;
        height: 100%;
        top: 0;
      }
  `};

  .see-more-container {
    z-index: 10;
  }

  .see-more-content {
    height: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: ${(props) => props.theme['$gray-dark']};
    ${breakpoints.md`
      padding-top: 3rem;
      padding-bottom: 3rem;
    `};

    svg {
      transition: all 0.2s ease-in-out;
      opacity: 1;
      margin-left: 1rem;
    }
    &:hover {
      svg {
        opacity: 1;
        margin-left: 1.5rem;
      }
    }
    ${breakpoints.md`
      svg {
        opacity: 0;
      }
    `};
  }
`

const SeeMoreText = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.25rem;
`

const FullScreenHero = ({
  header,
  ctaHeader,
  ctaText,
  ctaLink,
  ctaHeader2,
  ctaText2,
  ctaLink2,
}) => (
  <>
    <StyledBackgroundSection>
      <VideoBgPlayer />
      <Wrapper>
        <Container>
          <Row>
            <Col xs="12" md="9" lg="7">
              <H1>{header}</H1>
            </Col>
          </Row>
        </Container>
        <div className="google-review-sticker-wrapper d-none d-md-block">
          <GoogleReviewsSticker />
        </div>
        {/* Desktop CTA buttons */}
        <SeeMore className="d-none d-sm-block">
          <Container className="see-more-container">
            <Row>
              <Col xs="12" md="5" lg="4">
                <a
                  href={`${ctaLink}`}
                  className="see-more-content w-100 d-block"
                >
                  <Small
                    color="muted"
                    className="text-uppercase font-weight-medium"
                  >
                    {ctaHeader}
                  </Small>
                  <SeeMoreText className="mt-2 font-weight-medium">
                    <span>{ctaText}</span>
                    <Icon fill="white" width="24" name="arrow" />
                  </SeeMoreText>
                </a>
              </Col>
              <Col xs="12" md="5" lg="4">
                <Link to={ctaLink2} className="see-more-content w-100 d-block">
                  <Small
                    color="muted"
                    className="text-uppercase font-weight-medium"
                  >
                    {ctaHeader2}
                  </Small>
                  <SeeMoreText className="mt-2 font-weight-medium">
                    <span>{ctaText2}</span>
                    <Icon fill="white" width="24" name="arrow" />
                  </SeeMoreText>
                </Link>
              </Col>
            </Row>
          </Container>
        </SeeMore>
      </Wrapper>
    </StyledBackgroundSection>

    {/* Section on any screensize for spacing */}
    <Section>
      {/* Mobile CTA buttons */}
      <BsContainer className="d-sm-none">
        <SeeMore>
          <Row>
            <Col xs="12" md="4" className="border-bottom-2p">
              <a
                href={`${ctaLink}`}
                className="see-more-content w-100 d-block"
              >
                <Small
                  color="muted"
                  className="text-uppercase font-weight-medium"
                >
                  {ctaHeader}
                </Small>
                <SeeMoreText className="mt-2 font-weight-medium">
                  <span>{ctaText}</span>
                  <Icon fill="white" width="24" name="arrow" />
                </SeeMoreText>
              </a>
            </Col>
            <Col xs="12" md="4" className="border-bottom-2p">
              <Link
                to="/object-overzicht"
                className="see-more-content w-100 d-block"
              >
                <Small
                  color="muted"
                  className="text-uppercase font-weight-medium"
                >
                  {ctaHeader2}
                </Small>
                <SeeMoreText className="mt-2 font-weight-medium">
                  <span>{ctaText2}</span>
                  <Icon fill="white" width="24" name="arrow" />
                </SeeMoreText>
              </Link>
            </Col>
            <Col xs="12" md="4" className="border-bottom-2p pt-4 pb-4">
              <GoogleReviewsSticker />
            </Col>
          </Row>
        </SeeMore>
      </BsContainer>
    </Section>
  </>
)

FullScreenHero.propTypes = {
  header: PropTypes.string.isRequired,
  ctaHeader: PropTypes.string.isRequired,
  ctaLink: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaHeader2: PropTypes.string.isRequired,
  ctaText2: PropTypes.string.isRequired,
  ctaLink2: PropTypes.string.isRequired,
}

export default FullScreenHero
