import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Small } from '@bootstrap-styled/v4'
import breakpoints from '../../util/style/breakpoints'
import GoogleRating from './GoogleRating'
import { OutboundLink } from '../Link'
import { P } from '../Typography'

const Wrapper = styled.div`
  background: ${(props) => props.theme['$gray-dark']};

  ${breakpoints.md`
    border-radius: 50%;
    height: 242px;
    width: 242px;
    padding: 2.5rem 2.813rem;
  `};

  .read-more-reviews {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 1.5rem;
    padding-right: calc(30px / 2);
    margin-bottom: 1.3rem;

    ${breakpoints.md`
        position: inherit;
        padding: initial;
        margin-bottom: initial;
  `};
  }
`
const RatingAsDigits = styled(P)`
  font-size: 1.5rem;
  margin-bottom: 0;

  span {
    font-size: 2rem;
  }
`

const GoogleReviewsSticker = ({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        allGooglePlacesPlace {
          nodes {
            rating
            placeId: place_id
          }
        }
      }
    `}
    render={(data) => {
      const { rating, placeId } = data.allGooglePlacesPlace.nodes[0]

      return (
        <Wrapper className="d-flex flex-column align-items-md-center">
          <RatingAsDigits className="font-weight-medium">
            <span>{rating}</span> / 5{' '}
          </RatingAsDigits>
          <Small
            color="muted"
            className="text-uppercase font-weight-medium mb-3"
          >
            {intl.formatMessage({ id: 'CUSTOMER_REVIEW' })}
          </Small>
          <div className="mb-3">
            <GoogleRating rating={rating} />
          </div>
          <div className="read-more-reviews">
            <OutboundLink
              to={`https://search.google.com/local/reviews?placeid=${placeId}`}
              target="_blank"
              from="footer"
            >
              {intl.formatMessage({ id: 'READ_GOOGLE_REVIEWS' })}
            </OutboundLink>
          </div>
        </Wrapper>
      )
    }}
  />
)

export default injectIntl(GoogleReviewsSticker)
