import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import SectionHeading from '../SectionHeading'
import Button from '../Buttons'

const Wrapper = styled.div`
  display: flex;
`

const ContactMe = ({ id, header, subTitle, contactUs }) => (
  <Wrapper id={id}>
    <Container>
      <SectionHeading header={header} subTitle={subTitle} />
      <Row className="justify-content-center">
        <Col
          xs="12"
          md="8"
          className="d-flex justify-content-center flex-wrap flex-md-nowrap"
        >
          <Button
            name="primary"
            type="internal-link"
            className="mt-3 mt-md-0"
            to={`/contact`}
            from={header}
          >
            {contactUs}
          </Button>
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

ContactMe.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
}

export default ContactMe
